.header-container {
    width: 80%;
    margin: auto;
    padding-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}



@media screen and (max-width: 768px) {
    .header-container {
        width: 95%;
    }
}

