
.session-main-container{
    width: 80%;
    margin: auto;
}


.data-session-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-gap: 20px;
}

.register-container{
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 768px) {

    .session-main-container {
        width: 100%;
        margin: auto;
    }
}

