.shopping-container {
    width: 80%;
    margin: auto;
}

.containers-items-styles {

    display: flex;
    align-items: center;
    justify-content: center
}

.order-container {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

.order-container-action{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    margin-top: 20px;
}

.resume-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 25%;
    margin-top: 7px;
}

@media screen and (max-width: 768px) {

    .shopping-container {
        width: 98%;
        margin: auto;
    }

    .order-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 20px;
    }

    .resume-container{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
        padding-right: 20px;
    }
}
