.recover-main-container {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center
}


.recover-container {
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    width: 50%;
}

@media screen and (max-width: 768px) {

    .recover-main-container {
        width: 100%;
        margin: auto;
    }

    .recover-container {
        width: 100%;
    }
}
