.wrapper {
    display: grid;
    height: 100vh;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr auto auto auto;
    grid-template-areas:
    "header header header header"
    "sidebar main main main"
    "sidebar main main main"
    "footer footer footer footer";

    font-weight: 800;
    font-size: 12px;
    overflow: auto;
    transition: width 1s;
}

.wrapper_2 {
    display: grid;
    height: 100vh;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr auto auto auto;
    grid-template-areas:
    "header header header header"
    "main main main main"
    "main main main main"
    "footer footer footer footer";
    grid-gap: 0.2rem;
    font-weight: 800;
    font-size: 12px;
    overflow: auto;
    transition: width 1s;
}


header {
    grid-area: header;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

#sidebar {
    grid-area: sidebar;
    position: relative;
    z-index: 999;
}

main {
    grid-area: main;
    padding-right: 20px;
    padding-left: 20px
}

footer {
    grid-area: footer;
    height:  auto;
    background:#282A36;
}


@media screen and (max-width: 768px) {

    .wrapper {
        grid-template-rows: 0.2fr auto 1.2fr 0.8fr;
        grid-template-areas:
    "header header header header"
    "sidebar sidebar sidebar sidebar"
    "main  main main main"
    "footer  footer  footer footer";
    }


    .wrapper_2 {
        grid-template-rows: 0.2fr auto 1.2fr 0.8fr;
        grid-template-areas:
    "header header header header"
     "sidebar sidebar sidebar sidebar"
    "main  main main main"
    "footer  footer  footer footer";
    }

    main{
        padding-right: 10px;
        padding-left: 10px
    }
}
