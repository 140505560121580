.wrapper-detail{
    display: grid;
    height: 100vh;
    grid-template-columns: 0.8fr 1fr 1fr 1fr;
    grid-template-rows: 0.2fr auto auto auto;
    grid-template-areas:
    "header header header header"
    "main main main main"
    "footer footer footer footer";
    grid-gap: 0.2rem;
    font-weight: 800;
    font-size: 12px;
}

.header-detail{
    grid-area: header;
    background: #282A36;
    height: 70px
}

.main-detail-container {
    grid-area: main;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px

}

@media screen and (max-width: 768px) {

    .main-detail-container {
        padding-right: 10px;
        padding-left: 10px
    }
}

