.main-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    align-items: center;
    grid-gap: 5px;
}

.main-container_fill {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
    align-items: center;
    grid-gap: 5px;
}

.products-container {
    padding: 10px;
    border: 1px solid #b0bec5;
    border-radius: 3px;
    position: relative;
}

.description-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.legend {
    cursor: pointer;
}


.discount-container {
    position: absolute;
    z-index: 1000;
    top: 15px;
    right: 45px;
    height: 70px;
    width: 70px;
    border-radius: 35px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hack{
    display: none;
    position: absolute;
    z-index: 1000;
    background: transparent;
    top: 0;
    bottom: 0;
    width: 100%;
}


.carousel .slide .legend{
    background: #282A36
}

@media screen and (max-width: 768px) {

    .carousel .slide .legend{
        opacity: 0.75;
        background: #282A36
    }

    .hack{
        display: block;
        position: absolute;
        z-index: 1000;
        background: transparent;
        width: 100%;
        height: 33%
    }

    .hack_2{
        display: block;
        position: absolute;
        z-index: 1000;
        background: transparent;
        width: 100%;
        top: 700px;
        height:70px
    }

    .hack_3{
        display: block;
        position: absolute;
        z-index: 1000;
        background: transparent;
        width: 100%;
        bottom: 50px;
        height:130px
    }

}
