.sidebar-container {
    padding: 10px;
}

.filter-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
    position: relative;
}

.menu-side-container {
    display: none
}

.menu-side-container-show{
    display: flex;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .menu-side-container {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;

    }

    .menu-side-container-show {
        display: none
    }
}
