.details-container {
    width: 80%;
    margin: auto;
}

.images-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-gap: 20px;
}

.image-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    height: 300px;
    align-items: center;
}

.application-container {
    padding: 10px;
    border: 1px solid #b0bec5;
    border-radius: 3px
}

/*Schemas*/

.schema-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border: 1px solid #b0bec5;
    border-radius: 3px
}

.add-car-container {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border: 1px solid #b0bec5;
    border-radius: 3px
}

.discount-container-detail {
    position: absolute;
    z-index: 1000;
    top: 30px;
    right: 45px;
    height: 70px;
    width: 70px;
    border-radius: 35px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 768px) {

    .details-container {
        width: 100%;
        margin-top: 120px;
        position: relative
    }
    .hack-detail {
        display: block;
        position: absolute;
        z-index: 1000;
        background:transparent;
        width: 100%;
        height: 26%;
        top: 0;
    }

  .hack-detail_2 {
        display: block;
        position: absolute;
        z-index: 1000;
        background:transparent;
        width: 100%;
        height: 26%;
        top: 230px;
    }


}
