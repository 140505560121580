.footer-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-gap: 5px;
    background:#282A36;
    margin-top: 15px;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    grid-auto-rows: auto;
}

.details-footer-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    justify-content: flex-start;
    height: auto
}



@media screen and (max-width: 768px) {

   /* .footer-container{
        height:500px;
    }

    .details-footer-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        height:auto;
        padding-left: 10px;
    }*/

    .details-footer-container {
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

}


