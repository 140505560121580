.app-bar-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    background: #282A36;
    position: relative;
}

.toolbar-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 85%;
    margin: auto;
}

.logo-container {
    width: 20%;
}

.search-container {
    width: 60%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.user-option-container {
    width: 20%;
    z-index: 1000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.cart-container{
    z-index: 1000;
}

.user-display-container{
    display: flex;
    flex-direction: column;
}

/*SEARCH LIST*/

.products-list-container {
    width: 100%;
    margin: auto;
    background: #EAEEF3;
    position: absolute;
    top: 43px;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.75);
    z-index: 2100;
}

.list-container {
    border-bottom: 1px solid #b0bec5;
    background: #EAEEF3;
    display: flex;
    width: 98%;
    margin: auto;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;
    justify-content: flex-start;
}

/*
.list-container:hover {
    background: #cfd8dc;
    cursor: pointer;
}
*/

/*USER OPTION CONTAINER*/

.user-o-container {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.responsive-head-container {
    display: none
}

.icon-user-pos-2{
    display: none;
}

@media screen and (max-width: 1200px) {
    .toolbar-container {
        width: 90%;
    }
}

@media screen and (max-width: 1070px) {
    .toolbar-container {
        width: 95%;
    }


    .logo-container {
        width: 15%;
    }

    .search-container {
        width: 60%
    }

    .user-option-container {
        width: 25%;
    }

}

@media screen and (max-width: 940px) {
    .toolbar-container {
        width: 95%;
    }

    .logo-container {
        width: 15%;
    }

    .search-container {
        width: 65%
    }

    .user-option-container {
        width: 30%;
    }

}

.responsive-image-container{
    display: none;
}

@media screen and (max-width: 768px) {
    .responsive-head-container {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    .responsive-image-container{
        display: flex;
        width: 100%;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .toolbar-container {
        width: 95%;
        flex-direction: column;
        padding-bottom: 10px
    }

    .search-container {
        width: 100%
    }

    .logo-container {
        display: none
    }

    .user-option-container {
        display: none
    }

    .user-display-container{
        display: flex;
        flex-direction: row;
    }

    .icon-user-pos-1{
        display: none
    }

    .icon-user-pos-2{
        display: block;
    }

}


/* SearchBar.css */
.search-container_in {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 auto;
    border-radius: 5px;
    padding: 5px;
}

.search-container input {
    flex: 1;
    border: none;
    outline: none;
    padding: 5px;
    border-radius: 5px;
    height: 30px;
    font-size: 16px;
}

.search-container button {
    background: none;
    border: none;
    cursor: pointer;
}

.search-container img {
    width: 20px;
    height: 20px;
}

button {
    background-color: transparent; /* Fondo transparente por defecto */
    border: none; /* Sin borde */
    cursor: pointer;
    outline: none;
    padding: 10px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s ease;
    margin-left: 2px;
    border-radius: 3px;
}


.button:hover {
    background-color: rgba(46, 81, 137, 0.4)
}

